import ThemeSwitch from "./Component/ThemeSwitch";
import RequestList from "./Request/RequestList";
import RequestMessage from "./Request/ReuestMessage";
import RequestCreation from "./Request/RequestCreation";
import RequestDetails from "./Request/RequestDetails";
import RequestCreationReducer from "./Request/RequestCreationState/RequestCreationReducer";
import Home from "./HomePage/Home";
import HomePortfolio from "./HomePage/HomePortfolio";
import HomeService from "./HomePage/HomeService";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route
          path="/"
          element={
            <ThemeSwitch>
              <RequestList />
            </ThemeSwitch>
          }
        />
        <Route path="home" element={<Home />} />
        <Route path="home_portfolio/:page" element={<HomePortfolio />} />
        <Route path="home_service/:page" element={<HomeService />} />
        <Route
          path="request_list"
          element={
            <ThemeSwitch>
              <RequestList />
            </ThemeSwitch>
          }
        />
        <Route
          path="request_message"
          element={
            <ThemeSwitch>
              <RequestMessage />
            </ThemeSwitch>
          }
        />
        <Route
          path="request_creation"
          element={
            <ThemeSwitch>
              <RequestCreationReducer>
                <RequestCreation />
              </RequestCreationReducer>
            </ThemeSwitch>
          }
        />
        <Route
          path="request_details"
          element={
            <ThemeSwitch>
              <RequestDetails />
            </ThemeSwitch>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
