import RequestSideMenu from "../Component/RequestComponent/RequestSideMenu";
import MainHeader from "../Component/Header";
import {
  Layout,
  theme,
  Form,
  Input,
  Upload,
  message,
  Button,
  Row,
  Col,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef, useContext } from "react";
import RequestShowCaseButton from "../Component/RequestComponent/RequestShowCaseButton";
import styled from "styled-components";
import { ButtonStateContext } from "./RequestCreationState/RequestCreationReducer";
import { useLocation } from "react-router-dom";
import { RequestCreactionButtonData } from "./RequestCreationState/RequestCreationButtonData";
const { Dragger } = Upload;

function ScrollToElement(navigation) {
  setTimeout(() => {
    const Element = document.getElementById(navigation);
    if (Element) {
      Element.scrollIntoView({ behavior: "smooth" });
    }
  }, 200);
}

function classNameDepender(item, buttonState) {
  switch (item) {
    case "constructionMethodology":
      return buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "methodologyBuilding":
      return buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "methodologyPhase":
      return buttonState.methodologyBuilding.length > 0 &&
        buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "methodologyOutputs":
      return buttonState.methodologyPhase !== "None" &&
        buttonState.methodologyBuilding.length > 0 &&
        buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology"
        ? "show"
        : "";
    case "modelDevelopment":
      return buttonState.workStreams === "modelDevelopment" ? "show" : "";
    case "modelPhase":
      return (buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
        buttonState.workStreams === "modelDevelopment"
        ? "show"
        : "";
    case "modelEquipment":
      return buttonState.modelDevelopment.includes("modelEquipment") &&
        buttonState.workStreams === "modelDevelopment"
        ? "show"
        : "";
    case "modelType":
      return (buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
        buttonState.workStreams === "modelDevelopment" &&
        buttonState.modelPhase !== "None"
        ? "show"
        : "";
    case "modelUsage":
      return ((buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
        buttonState.workStreams === "modelDevelopment" &&
        buttonState.modelPhase !== "None" &&
        buttonState.modelType.length > 0) ||
        (buttonState.modelEquipment.length > 0 &&
          buttonState.workStreams === "modelDevelopment" &&
          buttonState.modelDevelopment.includes("modelEquipment"))
        ? "show"
        : "";
    case "uploadFiles":
      return (buttonState.methodologyOutputs.length > 0 &&
        buttonState.methodologyPhase !== "None" &&
        buttonState.methodologyBuilding.length > 0 &&
        buttonState.constructionMethodology.length > 0 &&
        buttonState.workStreams === "constructionMethodology") ||
        ((buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
          buttonState.modelDevelopment.includes("modelPermanentWorks")) &&
          buttonState.workStreams === "modelDevelopment" &&
          buttonState.modelPhase !== "None" &&
          buttonState.modelType.length > 0 &&
          buttonState.modelUsage.length > 0) ||
        (buttonState.modelEquipment.length > 0 &&
          buttonState.workStreams === "modelDevelopment" &&
          buttonState.modelDevelopment.includes("modelEquipment") &&
          buttonState.modelUsage.length > 0)
        ? "show"
        : "";
    default:
      return "";
  }
}

export default function RequestCreation() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [isDragOver, setIsDragOver] = useState(false);
  const [warning, setWarning] = useState(false);
  const { buttonState, dispatch } = useContext(ButtonStateContext);
  const contentRef = useRef();
  const dragCounter = useRef(0);
  const buttonStateRef = useRef(buttonState);
  const location = useLocation();
  const renderCount = useRef(0);
  const [form] = Form.useForm();
  useEffect(() => {
    renderCount.current += 1;
    if (location.state && renderCount.current === 1) {
      RequestData.some((item) => {
        if (
          item.uid === location.state.requestUid &&
          buttonStateRef.current === buttonState
        ) {
          Object.keys(item).forEach((key) => {
            if (key === "requestName") {
              dispatch({ type: "modifyRequestName", value: item[key] });
            } else if (key === "requestDescription") {
              dispatch({ type: "modifyRequestDescription", value: item[key] });
            } else if (key === "fileDescription") {
              item[key].forEach((file) => {
                dispatch({ type: "addFile", value: file });
              });
            } else {
              if (Array.isArray(item[key])) {
                item[key].forEach((value) => {
                  if (value) {
                    dispatch({
                      type: "multiSelect",
                      value: value,
                      category: key,
                    });
                  }
                });
              } else {
                dispatch({
                  type: "singleSelect",
                  value: item[key],
                  category: key,
                });
              }
            }
          });

          return true;
        }
        return false;
      });
    }
  });

  useEffect(() => {
    form.setFieldsValue({
      projectName: buttonState.requestName,
      description: buttonState.requestDescription,
    });
  }, [buttonState.requestName, buttonState.requestDescription, form]);

  useEffect(() => {
    if (buttonState !== buttonStateRef.current) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  }, [buttonState]);

  useEffect(() => {
    if (buttonState.workStreams === "constructionMethodology") {
      ScrollToElement("constructionMethodology");
    } else if (buttonState.workStreams === "modelDevelopment") {
      ScrollToElement("modelDevelopment");
    } else {
    }
  }, [buttonState.workStreams]);

  const prevConstructionMethodologyRef = useRef();
  useEffect(() => {
    if (
      buttonState.constructionMethodology.length > 0 &&
      prevConstructionMethodologyRef.current !== 1
    ) {
      prevConstructionMethodologyRef.current = 1;

      ScrollToElement("methodologyBuilding");
    } else if (buttonState.constructionMethodology.length === 0) {
      prevConstructionMethodologyRef.current = 0;
    }
  }, [buttonState.constructionMethodology]);

  const prevModelDevelopmentRef = useRef();
  useEffect(() => {
    if (
      buttonState.methodologyBuilding.length > 0 &&
      prevModelDevelopmentRef.current !== 1
    ) {
      prevModelDevelopmentRef.current = 1;
      ScrollToElement("methodologyPhase");
    } else if (buttonState.methodologyBuilding.length === 0) {
      prevModelDevelopmentRef.current = 0;
    }
  }, [buttonState.methodologyBuilding]);

  const prevmethodologyPhaseRef = useRef();
  useEffect(() => {
    if (
      buttonState.methodologyPhase !== "None" &&
      prevmethodologyPhaseRef.current !== 1
    ) {
      prevmethodologyPhaseRef.current = 1;
      ScrollToElement("methodologyOutputs");
    } else if (buttonState.methodologyPhase === "None") {
      prevmethodologyPhaseRef.current = 0;
    }
  }, [buttonState.methodologyPhase]);

  const prevModelOutputsRef = useRef();
  useEffect(() => {
    if (
      prevModelOutputsRef.current !== 1 &&
      (buttonState.modelDevelopment.includes("modelTemporaryWorks") ||
        buttonState.modelDevelopment.includes("modelPermanentWorks"))
    ) {
      prevModelOutputsRef.current = 1;
      ScrollToElement("modelPhase");
    } else if (
      prevModelOutputsRef.current !== 2 &&
      buttonState.modelDevelopment.includes("modelEquipment")
    ) {
      prevModelOutputsRef.current = 2;
      ScrollToElement("modelEquipment");
    } else if (buttonState.modelDevelopment.length === 0) {
      prevModelOutputsRef.current = 0;
    }
  }, [buttonState.modelDevelopment]);

  const prevModelPhaseRef = useRef();
  useEffect(() => {
    if (buttonState.modelPhase !== "None" && prevModelPhaseRef.current !== 1) {
      prevModelPhaseRef.current = 1;
      ScrollToElement("modelType");
    } else if (buttonState.modelPhase === "None") {
      prevModelPhaseRef.current = 0;
    }
  }, [buttonState.modelPhase]);

  const prevModelTypeRef = useRef();
  useEffect(() => {
    if (
      prevModelTypeRef.current !== 1 &&
      (buttonState.modelEquipment.length === 1 ||
        buttonState.modelType.length === 1)
    ) {
      prevModelTypeRef.current = 1;
      ScrollToElement("modelUsage");
    } else if (
      buttonState.modelEquipment.length === 0 &&
      buttonState.modelType.length === 0
    ) {
      prevModelTypeRef.current = 0;
    }
  }, [buttonState.modelEquipment, buttonState.modelType]);

  const prevModelUsageRef = useRef();
  const preMethodologyOutputsRef = useRef();
  useEffect(() => {
    if (
      buttonState.modelUsage.length === 1 &&
      prevModelUsageRef.current !== 1
    ) {
      prevModelUsageRef.current = 1;
      if (window.innerWidth < 1600) {
        ScrollToElement("uploadFiles0");
      }
    } else if (
      buttonState.methodologyOutputs.length === 1 &&
      preMethodologyOutputsRef.current !== 1
    ) {
      preMethodologyOutputsRef.current = 1;

      if (window.innerWidth < 1600) {
        ScrollToElement("uploadFiles0");
      }
    } else if (
      buttonState.modelUsage.length === 0 &&
      buttonState.methodologyOutputs.length === 0
    ) {
      prevModelUsageRef.current = 0;
      preMethodologyOutputsRef.current = 0;
    } else if (buttonState.modelUsage.length === 0) {
      prevModelUsageRef.current = 0;
    } else if (buttonState.methodologyOutputs.length === 0) {
      preMethodologyOutputsRef.current = 0;
    }
  }, [buttonState.modelUsage, buttonState.methodologyOutputs]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleDragEnter = (e) => {
      e.preventDefault();
      dragCounter.current++;
      setIsDragOver(dragCounter.current > 0);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      dragCounter.current--;
      setIsDragOver(dragCounter.current > 0);
    };
    const handleDrop = (e) => {
      e.preventDefault();
      dragCounter.current = 0;
      setIsDragOver(false);
    };

    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);
    return () => {
      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  const uploadDocumentProps = {
    name: "file",
    multiple: true,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    beforeUpload: (file) => {
      if (
        buttonState.fileDescription.find(
          (existingFile) => existingFile.name === file.name
        )
      ) {
        message.error(
          `A file with the name ${file.name} has already been uploaded.`
        );
        return Upload.LIST_IGNORE;
      }
      return true;
    },

    onChange(info) {
      const { status, name } = info.file;
      if (status === "uploading") {
        const isFileAlreadyInState = buttonState.fileDescription.find(
          (existingFile) => existingFile.uid === info.file.uid
        );
        if (!isFileAlreadyInState) {
          dispatch({ type: "addFile", value: info.file });
        }
      }
      if (status === "done") {
        message.success(`${name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${name} file upload failed.`);
      }
    },
    onRemove: (info) => {
      dispatch({ type: "removeFile", value: info.uid });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      style: {
        width: "100%",
        paddingBottom: 5,
      },
      strokeWidth: 2,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    console.log(buttonState);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const UploadModule = (Top) => {
    return (
      <React.Fragment>
        <FormItemAnimationContainer
          id={"uploadFiles" + Top}
          className={
            Top ? "show" : classNameDepender("uploadFiles", buttonState)
          }
        >
          <StyledFormItem
            label="Please Upload the files"
            name="uploadFiles"
            layout="vertical"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <HighlightedDragger
                {...uploadDocumentProps}
                isDragOver={isDragOver}
                fileList={buttonState.fileDescription}
                itemRender={(originNode, file) => {
                  return (
                    <div style={{ width: "100%", height: "auto" }}>
                      <div
                        style={{
                          width: "35%",
                          float: "left",
                          marginBottom: 5,
                          height: 32,
                        }}
                      >
                        {originNode}
                      </div>
                      <Input
                        style={{
                          width: "60%",
                          float: "right",
                          marginTop: 5,
                        }}
                        onChange={(e) => {
                          file.description = e.target.value;
                          dispatch({
                            type: "addFileDescription",
                            value: file,
                          });
                        }}
                        placeholder={"Desciption of the file"}
                      />
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  {buttonStateRef.current === buttonState
                    ? "Please answer the questions and there would be a recommanded uploaded file list."
                    : "Drawing, Sketches,Models, Meeting Notes, Past Work.(Displayed information will based on the previous selection.)"}
                </p>
              </HighlightedDragger>
            </div>
          </StyledFormItem>
        </FormItemAnimationContainer>
        <FormItemAnimationContainer
          className={classNameDepender("uploadFiles", buttonState)}
        >
          <StyledFormItem
            wrapperCol={{
              span: 24,
            }}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginTop: 24,
                  marginBottom: 64,
                }}
              >
                Submit
              </Button>
            </div>
          </StyledFormItem>
        </FormItemAnimationContainer>
      </React.Fragment>
    );
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  //This function is used to handle search request, info?.source will return 'input'
  return (
    <MainHeader select="Request" warning={warning}>
      <RequestSideMenu select="2" warning={warning}>
        <Layout.Content
          style={{
            marginLeft: 2,
            minHeight: 150,
            background: colorBgContainer,
            height: height - 66,
            overflowY: window.innerWidth > 1600 ? "hidden" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: height - 66,
            }}
            ref={contentRef}
          >
            <Form
              name="basic"
              labelAlign="left"
              labelWrap
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              style={{
                width: "100%",
                maxWidth: 0.9 * (width - 220),
                height: height - 66,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                remember: true,
                projectName: buttonState.requestName,
                description: buttonState.requestDescription,
              }}
              onValuesChange={(changedValues, allValues) => {
                if (changedValues.projectName) {
                  dispatch({
                    type: "modifyRequestName",
                    value: changedValues.projectName,
                  });
                }
                if (changedValues.description) {
                  dispatch({
                    type: "modifyRequestDescription",
                    value: changedValues.description,
                  });
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Row
                  style={{
                    maxWidth: 3000,
                    display: "flex",
                    justifyContent: "center",
                    height: height - 66,
                  }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={20}
                    xxl={8}
                    style={{
                      position:
                        window.innerWidth > 1600 ? "sticky" : "relative",
                      top: 0,
                      height: "auto",
                    }}
                  >
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        style={{ paddingRight: "2vw" }}
                      >
                        <h1
                          style={{
                            fontSize: 30,
                            paddingBottom: 50,
                            paddingLeft: 0,
                            textAlign:
                              window.innerWidth > 1600 ? "left" : "center",
                          }}
                        >
                          Create Your Request{" "}
                        </h1>
                        <Form.Item
                          label="Title"
                          name="projectName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your project name!",
                            },
                          ]}
                        >
                          <Input style={{ flexGrow: 1 }} />
                        </Form.Item>

                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input your description of your project!",
                            },
                          ]}
                        >
                          <Input.TextArea span={4} />
                        </Form.Item>
                      </Col>
                      <StyledCol
                        xs={0}
                        sm={0}
                        md={0}
                        lg={0}
                        xl={0}
                        xxl={24}
                        style={{
                          height: height - 337,
                          overflowY: "auto",
                        }}
                      >
                        {UploadModule(1)}
                      </StyledCol>
                    </Row>
                  </Col>

                  <StyledCol
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={16}
                    style={{
                      overflowY: window.innerWidth > 1600 ? "auto" : "hidden",
                      height: window.innerWidth > 1600 ? height - 66 : "auto",
                      // marginTop: window.innerWidth > 1600 ? -130 : 0,
                    }}
                  >
                    <StyledFormItem
                      label={RequestCreactionButtonData[0].question}
                      name={RequestCreactionButtonData[0].category}
                      layout="vertical"
                      className={"show"}
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      style={{ paddingTop: 50 }}
                    >
                      <RequestShowCaseButton>
                        {RequestCreactionButtonData[0]}
                      </RequestShowCaseButton>
                    </StyledFormItem>
                    {RequestCreactionButtonData.slice(1).map((item) => {
                      let className = classNameDepender(
                        item.category,
                        buttonState
                      );
                      return (
                        <FormItemAnimationContainer
                          id={item.category}
                          className={className}
                        >
                          <StyledFormItem
                            label={item.question}
                            name={item.category}
                            Layout="vertical"
                            labelCol={{
                              span: 24,
                            }}
                            wrapperCol={{
                              span: 24,
                            }}
                          >
                            <RequestShowCaseButton>
                              {item}
                            </RequestShowCaseButton>
                          </StyledFormItem>
                        </FormItemAnimationContainer>
                      );
                    })}
                  </StyledCol>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={0}>
                    {UploadModule(0)}
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Layout.Content>
      </RequestSideMenu>
    </MainHeader>
  );
}

const StyledCol = styled(Col)`
  position: relative;
  overflow-y: auto;
  height: 75vh;

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: darkgrey;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0 !important;

  .ant-form-item-label {
    text-align: center;
  }
  .ant-form-item-label > label {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24;
    height: auto;
  }
`;
const FormItemAnimationContainer = styled.div`
  width: 100%;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out,
    padding 0.3s ease-in-out;
  &.show {
    opacity: 1;
    max-height: 1500px;
  }
`;
const HighlightedDragger = styled(Dragger)`
  .ant-upload.ant-upload-drag {
    transition: box-shadow 0.3s ease-in-out;
    max-height: 200;

    overflow-y: hidden;
    margin-top: 30 !important;
    margin-bottom: 30 !important;
    box-shadow: ${(props) =>
      props.isDragOver
        ? "0 0 20px rgba(24, 144, 255, 0.5)"
        : "none"} !important;
  }
  .ant-upload-list {
    margin-top: -30;
    margin-bottom: 30 !important;
  }
`;
const RequestData = [
  {
    uid: "123456",
    requestName: "Testing Request",
    requestDescription: "This is a test request",
    workStreams: "constructionMethodology",
    constructionMethodology: ["methodologyVerifying"],
    methodologyBuilding: [
      "methodologyBuildingBridge",
      "methodologyBuildingMSB",
    ],
    methodologyPhase: "methodologyPhaseECI",
    methodologyOutputs: [
      "methodologyOutputsTD",
      "methodologyOutputsSimulations",
    ],
    fileDescription: [
      {
        uid: "-1",
        name: "Construction Guideline.png",

        url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
      },
    ],
  },
];
