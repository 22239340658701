import RequestSideMenu from "../Component/RequestComponent/RequestSideMenu";
import MainHeader from "../Component/Header";
import {
  Layout,
  theme,
  Button,
  List,
  Space,
  Modal,
  Input,
  Flex,
  Image,
  Upload,
} from "antd";
import {
  PlusCircleOutlined,
  SendOutlined,
  LinkOutlined,
} from "@ant-design/icons";

import VirtualList from "rc-virtual-list";
import React, { useState, useEffect } from "react";

const { Search } = Input;

export default function RequestMessage() {
  const [height, setHeight] = useState(window.innerHeight);
  const [selectedTechnician, setSelectedTechnician] = useState(0);
  const [inputMessage, setInputMessage] = useState("");
  const [isAddChatOpen, setIsAddChatOpen] = useState(false);
  const [messageList, setMessageList] = useState(messageListA);

  const showAddModal = () => {
    setIsAddChatOpen(true);
  };

  const handleAddOk = () => {
    setIsAddChatOpen(false);
  };

  const handleAddCancel = () => {
    setIsAddChatOpen(false);
  };
  const onSearch = (value, _e, info) => console.log(value);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer, colorBgLayout },
  } = theme.useToken();
  //This function is used to handle search request, info?.source will return 'input'
  return (
    <MainHeader select="Request">
      <RequestSideMenu select="3">
        <Layout.Content
          style={{
            marginLeft: 2,
            minHeight: 150,
            background: colorBgContainer,
          }}
        >
          <Flex justify="space-between">
            <Flex justify="space-between" vertical>
              <Button
                onClick={showAddModal}
                style={{
                  width: 350,
                  height: 80,
                  fontSize: 30,
                  borderRadius: 0,
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                }}
              >
                <div
                  style={{
                    float: "left",
                    height: "100%",
                    alignContent: "center",
                    padding: 16,
                  }}
                >
                  Chats
                </div>
                <div
                  style={{
                    float: "right",
                    height: "100%",
                    alignContent: "center",
                    padding: 16,
                  }}
                >
                  <PlusCircleOutlined />
                </div>
              </Button>
              <Modal
                title="Basic Modal"
                open={isAddChatOpen}
                onCancel={handleAddCancel}
                footer={() => <></>}
                width={400}
              >
                <Search
                  placeholder="search request by name"
                  onSearch={onSearch}
                />
                <List>
                  <VirtualList
                    data={allMemberSelection}
                    height={400}
                    itemHeight={47}
                    itemKey="name"
                  >
                    {(item) => (
                      <List.Item
                        key={item.name}
                        style={{ padding: 0, height: 62, overflowX: "hidden" }}
                      >
                        <Button
                          onClick={() => {
                            handleAddOk();
                          }}
                          type="text"
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            borderRadius: 0,
                          }}
                        >
                          {item.name}
                        </Button>
                      </List.Item>
                    )}
                  </VirtualList>
                </List>
              </Modal>

              <List>
                <VirtualList
                  data={memberList}
                  height={height - 146}
                  itemHeight={47}
                  itemKey="title"
                >
                  {(item, index) => {
                    let type = "text";
                    if (index === selectedTechnician) {
                      type = "primary";
                    }
                    return (
                      <List.Item
                        key={item.title}
                        style={{ padding: 0, height: 80, overflowX: "hidden" }}
                      >
                        <Button
                          onClick={() => {
                            switch (item.title) {
                              case "Technician A":
                                setMessageList(messageListA);
                                break;
                              case "Technician B":
                                setMessageList(messageListB);
                                break;
                              case "Technician C":
                                setMessageList(messageListC);
                                break;
                              case "Technician D":
                                setMessageList(messageListD);
                                break;
                              default:
                                break;
                            }
                            setSelectedTechnician(index);
                            console.log(item.title);
                          }}
                          type={type}
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingLeft: 24,
                            paddingRight: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            borderRadius: 0,
                            textAlign: "left",
                          }}
                        >
                          <Space direction="horizontal">
                            <Space
                              direction="vertical"
                              size="small"
                              style={{
                                width: 200,
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  height: "100%",
                                  fontWeight: "bold",
                                  fontSize: 16,
                                }}
                              >
                                {item.title}
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  height: "100%",
                                  fontSize: 12,
                                }}
                              >
                                {item.log}
                              </div>
                            </Space>
                            <div
                              style={{
                                paddingLeft: 40,
                                fontWeight: "bold",
                                color: "green",
                              }}
                            >
                              {item.status}
                            </div>
                          </Space>
                        </Button>
                      </List.Item>
                    );
                  }}
                </VirtualList>
              </List>
            </Flex>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                borderLeftWidth: 2,
                borderLeftColor: colorBgLayout,
                borderLeftStyle: "solid",
              }}
            >
              <List
                style={{
                  paddingLeft: "20px",
                  paddingTop: "10px",
                }}
              >
                <VirtualList
                  data={messageList}
                  height={height - 218}
                  itemHeight={47}
                  itemKey="title"
                >
                  {(item) => {
                    let infoDisplay = "none";
                    let imageDisplay = "none";
                    if (item.infoType === "text") {
                      infoDisplay = "";
                    } else if (item.infoType === "image") {
                      imageDisplay = "";
                    }
                    return (
                      <List.Item
                        key={item.time}
                        style={{
                          borderBottom: "none",
                          fontSize: 16,
                          margin: "10px 0",
                          padding: "8px",
                        }}
                      >
                        <Space direction="vertical">
                          <div>
                            <strong>{item.senter}</strong> - {item.time}
                          </div>

                          <div style={{ display: infoDisplay }}>
                            {item.information}
                          </div>
                          <Image
                            width={150}
                            src={item.information}
                            style={{ display: imageDisplay }}
                          ></Image>
                        </Space>
                      </List.Item>
                    );
                  }}
                </VirtualList>
              </List>
              <div
                style={{
                  backgroundColor: colorBgLayout,
                  height: 142,
                  alignContent: "center",
                  paddingLeft: 24,
                }}
              >
                <Flex direction="horizontal" style={{ width: "100%" }}>
                  <Input.TextArea
                    placeholder="Type your message"
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    style={{ width: "95%" }}
                    onChange={(e) => setInputMessage(e.target.value)}
                  />
                  <Space
                    direction="vertical"
                    size={"large"}
                    style={{ paddingLeft: 16, paddingRight: 16 }}
                  >
                    <Upload {...attachmentProps}>
                      <LinkOutlined
                        style={{
                          fontSize: 16,
                          paddingTop: 8,
                        }}
                        onClick={(e) => {
                          console.log("LaoDa");
                        }}
                      />
                    </Upload>
                    <SendOutlined
                      style={{ fontSize: 16, position: "fixed", bottom: 32 }}
                      onClick={(e) => {
                        console.log(inputMessage);
                      }}
                    />
                  </Space>
                </Flex>
              </div>
            </div>
          </Flex>
        </Layout.Content>
      </RequestSideMenu>
    </MainHeader>
  );
}

const memberList = [
  {
    title: "Technician A",
    log: "See you again.",
    status: "",
  },
  {
    title: "Technician B",
    log: "OK thats good.",
    status: "New",
  },
  {
    title: "Technician C",
    log: "All done.",
    status: "",
  },
  {
    title: "Technician D",
    log: "We will process about that.",
    status: "",
  },
];

const allMemberSelection = [
  {
    name: "Technician A",
  },
  {
    name: "Technician B",
  },
  {
    name: "Technician C",
  },
  {
    name: "Technician D",
  },
  {
    name: "Technician E",
  },
  {
    name: "Technician F",
  },
  {
    name: "Technician G",
  },
  {
    name: "Technician H",
  },
];

const messageListA = [
  {
    senter: "Technician A",
    time: "15:35",
    information: "What can I say?",
    infoType: "text",
  },
  {
    senter: "User Name",
    time: "15:36",
    information: "Miss you Large Lao",
    infoType: "text",
  },
  {
    senter: "Technician A",
    time: "15:37",
    information: "Helicoptor Helicotor",
    infoType: "text",
  },
  {
    senter: "Technician A",
    time: "15:39",
    information:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
    infoType: "image",
  },
  {
    senter: "User Name",
    time: "15:38",
    information: "See you again",
    infoType: "text",
  },
];
const messageListB = [
  {
    senter: "Technician B",
    time: "16:00",
    information: "How can I assist you?",
    infoType: "text",
  },
  {
    senter: "User Name",
    time: "16:01",
    information: "I need help with my project",
    infoType: "text",
  },
  {
    senter: "Technician B",
    time: "16:02",
    information: "Sure, I'm here to help",
    infoType: "text",
  },
];
const messageListC = [
  {
    senter: "Technician C",
    time: "16:30",
    information: "What do you need?",
    infoType: "text",
  },
  {
    senter: "User Name",
    time: "16:31",
    information: "I need help with my project",
    infoType: "text",
  },
  {
    senter: "Technician C",
    time: "16:32",
    information: "Sure, I'm here to help",
    infoType: "text",
  },
];
const messageListD = [
  {
    senter: "Technician D",
    time: "17:00",
    information: "What do you need?",
    infoType: "text",
  },
  {
    senter: "User Name",
    time: "17:01",
    information: "I need help with my project",
    infoType: "text",
  },
  {
    senter: "Technician D",
    time: "17:00",
    information: "Goodbye, have a nice day!",
    infoType: "text",
  },
  {
    senter: "User Name",
    time: "17:01",
    information: "Goodbye!",
    infoType: "text",
  },
  {
    senter: "Technician D",
    time: "17:02",
    information:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
    infoType: "image",
  },
];
const attachmentProps = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  accept: ".jpg,.jpeg,.png",
  maxCount: 1,
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      console.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.error(`${info.file.name} file upload failed.`);
    }
  },
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    style: {
      width: 250,
      fontSize: 12,
    },
    strokeWidth: 3,
    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
  },
};
