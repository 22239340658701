import styled, { keyframes, css } from "styled-components";
import { useState, useEffect, useRef } from "react";

export default function SubPageDetails(props) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible && entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isVisible]);
  useEffect(() => {
    setIsVisible(false);
  }, [props.children]);
  return (
    <SubPageDetail
      ismobile={props.isMobile}
      isVisible={isVisible}
      ref={ref}
      style={props.style}
    >
      {props.children}
    </SubPageDetail>
  );
}

const fadeInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const SubPageDetail = styled.div`
  opacity: 0;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fadeInFromLeft} 1s ease-out forwards;
      animation-delay: ${(props) => props.delay || 0}s;
    `}
  font-size: calc(
    100vh * ${(props) => (props.ismobile ? 16 : 28)} /
      ${(props) => (props.ismobile ? 720 : 1080)}
  );
  color: white;
  padding-right: ${(props) => (props.ismobile ? "10vw" : "5vw")};
  padding-left: ${(props) => (props.ismobile ? "10vw" : "5vw")};

  font-style: arial;
  width: auto;
`;
