import HomeHeader from "../Component/HomeComponent/HomeHeader";
import styled, { keyframes } from "styled-components";
import { useState, useEffect, useRef } from "react";
import ShowCaseCardRow from "../Component/HomeComponent/ShowCaseCardRow";
import { Col, Row, Button } from "antd";
import { services, portfolios } from "./StaticData/HomeData";
import HomeFooter from "../Component/HomeComponent/HomeFooter";
import {
  PageContainer,
  SectionContainer,
  TitleContainer,
  DetailsContainer,
  FirstSectionContainer1,
  SubPageTitle,
} from "../Component/HomeComponent/GeneralComponents";
import { createFromIconfontCN, TeamOutlined } from "@ant-design/icons";
import AboutUs from "../Component/HomeComponent/AboutUs";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_4531892_qm19xvlhz3n.js",
});

export default function Home() {
  const [ismobile, setIsMobile] = useState(0);
  const moveTitleRef = useRef();

  function ScrollToElement(navigation) {
    const Element = document.getElementById(navigation);
    if (Element) {
      Element.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX } = event;
      const dx = (window.innerWidth / 2 - clientX) / 100;

      moveTitleRef.current.style.transform = `translateX(${dx}px)`;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? 1 : 0);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that ismobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash;
      if (hash) {
        const id = hash.substring(1);
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      }
    }, 100);
  }, []);

  return (
    <PageContainer>
      <FirstSectionContainer1
        ismobile={ismobile}
        id="main"
        bg="/HomePageBackgroundPic.png"
        bgmobile="/Jubilee Bridge/HomePageBackgroundPic.png"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <HomeHeader style={{ position: "relative", zindex: "2" }} />
        <div ref={moveTitleRef}>
          <TitleContainer ismobile={ismobile}>
            <div>Where</div>
            <div>Construction</div>
            <div>Meets Innovation</div>
          </TitleContainer>
          <DetailsContainer
            ismobile={ismobile}
            style={{
              paddingTop: ismobile ? "10vh" : "10vh",
              width: ismobile ? "80vw" : "30vw",
              fontWeight: 200,
              lineHeight: 1.2,
            }}
          >
            <div>
              At RF Sons Engineering, we specialize in leveraging digital tools
              to innovate construction processes.
            </div>

            <RoundButton
              ismobile={ismobile}
              onClick={() => {
                ScrollToElement("portfolio");
              }}
              style={{ marginTop: ismobile ? "15vh" : "10vh" }}
            >
              Learn More
            </RoundButton>
          </DetailsContainer>
        </div>
        <Row
          style={{
            position: "absolute",
            bottom: "8vh",
            width: "100%",
            display: ismobile ? "none" : "flex",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <StyledCol span={8} delay={0}>
            <StyledButton
              onClick={() => {
                ScrollToElement("portfolio");
              }}
              type="text"
              icon={<IconFont type="icon-portfolio" />}
            >
              Portfolio
            </StyledButton>
          </StyledCol>
          <StyledCol span={8} delay={0.25}>
            <StyledButton
              onClick={() => {
                ScrollToElement("services");
              }}
              type="text"
              icon={<IconFont type="icon-jianmo" />}
            >
              Services
            </StyledButton>
          </StyledCol>
          <StyledCol span={8} delay={0.5}>
            <StyledButton
              onClick={() => {
                ScrollToElement("about");
              }}
              type="text"
              icon={<TeamOutlined />}
            >
              About Us
            </StyledButton>
          </StyledCol>
        </Row>
      </FirstSectionContainer1>

      <SectionContainer id="portfolio" style={{ paddingTop: "5vh" }}>
        <SubPageTitle
          ismobile={ismobile}
          style={{
            paddingTop: ismobile ? "1.5vh" : "3vh",
            paddingBottom: 0,
            marginLeft: 0,
          }}
        >
          Our Portfolio
        </SubPageTitle>

        <ShowCaseCardRow>{portfolios}</ShowCaseCardRow>
      </SectionContainer>
      <SectionContainer id="services">
        <SubPageTitle
          ismobile={ismobile}
          style={{
            paddingTop: ismobile ? "1.5vh" : "3vh",
            paddingBottom: 0,
            marginLeft: 0,
          }}
        >
          Our Services
        </SubPageTitle>
        <ShowCaseCardRow>{services}</ShowCaseCardRow>
      </SectionContainer>
      <AboutUs ismobile={ismobile} />
      <HomeFooter />
    </PageContainer>
  );
}

const ripple = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(2, 138, 138, 0.4);
  }

  100% {
    box-shadow: 0 0 0 35px rgba(2, 138, 138, 0);
  }
`;
const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const RoundButton = styled(Button)`
  display: ${(props) => (props.ismobile ? "block" : "none")};
  background-color: transparent;
  border: 1px solid white;
  padding: 20px 30px;
  height: auto;
  margin-top: 5vh;
  color: white;
  white-space: wrap;
  font-size: calc(100vh * 14 / 720);
  animation: ${ripple} 1.5s infinite;
`;
const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInFromBottom} 2s ease-out;
  animation-delay: ${(props) => props.delay || 0}s;
  animation-fill-mode: backwards;
`;
const StyledButton = styled(Button)`
  font-size: calc(
    100vh * ${(props) => (props.ismobile ? 20 : 20)} /
      ${(props) => (props.ismobile ? 720 : 1080)}
  );

  background-color: transparent;
  color: white;
  &&:hover {
    color: #18dcff !important;
    background-color: transparent !important;
  }
`;
