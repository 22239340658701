export const HomeConstructionMethodology = {
  title: "Construction Methodology",
  description:
    "Construction methodology serves as the cornerstone of every successful construction project, providing a comprehensive road map for its execution. As virtual construction engineers, we recognize the pivotal role that a well-crafted construction methodology plays in driving project success.",
  img: "/Jubilee Bridge/HomePageBackgroundPic.png",
  content: [
    {
      title: "Efficiency",
      content:
        "Knowing things won't work before getting onto site could mean the difference between project success or failure. It's no wonder all the big companies in the world are turning to virtual construction to maintain high efficiency. ",
      img: "/Construction Methodology Examples/Tender Upper Hand Photo.png",
    },
    {
      title: "Cost Effectiveness",
      content:
        " Cost, cost, cost. The construction industry is run by cost. We all know the ones doing the work in the fastest time, the lowest cost and highest quality are the ones winning tenders. Construction methodology isn't just the creation of fancy drawings and animations, it is a vital workflow that immediately identifies and addresses pain points. ",
      img: "/Construction Methodology Examples/Cost Effectiveness Photo.png",
    },
    {
      title: "Tender Upper Hand",
      content:
        "People are visual. It's one thing to say how you'll build something, but showing how you are going to build something is when things get real. Clients will be able to immediately recognize your ability to assess risks and create realistic plans, because you have done so virtually and visually.",
      img: "/Construction Methodology Examples/Tender Upper Hand Photo.png",
    },
    {
      title: "Collaboration",
      content:
        "We collaborate closely with our clients and project stakeholders to understand project requirements, goals, and constraints. Through collaborative discussions and workshops, we identify key project drivers and develop a tailored approach that aligns with project objectives.",
      img: "/Construction Methodology Examples/Collaboration Photo.png",
    },
    {
      title: "Innovation",
      content:
        "Innovation: Leveraging cutting-edge technology and best practices, we employ innovative solutions to optimize project delivery. From advanced 3D modeling to drone capture and reality simulations, we harness technology to enhance visualization and decision-making throughout the construction process.",
      img: "/Construction Methodology Examples/Innovation Photo.png",
    },
    {
      title: "Expertise",
      content:
        "Our team of experienced construction professionals brings a wealth of expertise to every project. With a deep understanding of industry standards and regulations, coupled with practical on-site experience, we develop construction methodologies that are effective, compliant, and tailored to each project's unique requirements.",
      img: "/Construction Methodology Examples/Expertise Photo.png",
    },
  ],
};

export const Home3DModeling = {
  title: "Custom 3D Modeling",
  description:
    "From installing new bridges, creating vast tunnels or improving existing buildings, every construction project is an endeavor to change the reality around us. However capturing accurately capturing this reality in the virtual world can be often be a complex challenge. but with our expertise in the latest reality capture technolgoies and best 3D software practice, we can make that process as easy as Ctrl C, Ctrl V. ",
  img: "/3D Modeling Examples/Header PNG.png",
  content: [
    {
      title: "Precision in Surveying",
      content:
        "Being able to access surface areas with the click of a button, analyse volumes in seconds and produce operating limits with ease, are all things made possible with custom 3D modeling. With 3D modeling capture, we achieve unparalleled accuracy. This allows for exact site data collection and efficient project planning, ensuring every measurement and material estimate corresponds seamlessly with real-world conditions.",
      img: "/3D Modeling Examples/Precision Survey Photo.png",
    },
    {
      title: "Versatility in Applications",
      content:
        "Custom 3D modeling capture adapts flawlessly to any construction need. Whether it's designing complex machinery or constructing sturdy structures, our technology ensures that every project detail is meticulously accounted for, supporting a broad range of industries.",
      img: "/3D Modeling Examples/Versatility in Applications Photo.png",
    },
    {
      title: "Innovation in Design and Construction",
      content:
        "The power to predict and solve potential issues before they impact your budget or schedule is now at our fingertips. By simulating construction scenarios and optimizing strategies in advance, our 3D modeling helps avoid costly setbacks, enhances safety, and improves overall project quality.",
      img: "/3D Modeling Examples/Innovation in Design Photo.png",
    },
    {
      title: "Advanced Technology Integration",
      content:
        "Employing cutting-edge technologies like aerial drone surveys, we capture detailed topographical and structural data. This information is crucial in creating precise 3D models that inform all project phases, from design to completion.",
      img: "/3D Modeling Examples/Advanced Technology Photo.png",
    },
    {
      title: "Customized Modeling Solutions",
      content:
        "We tailor our modeling techniques to fit the specific challenges and requirements of each project, ensuring that our approach addresses the unique scope and complexity of your construction needs.",
      img: "/3D Modeling Examples/Custom Modelling Solutions Photo.png",
    },
    {
      title: "Expert Analysis and Implementation",
      content:
        "Our skilled virtual construction engineers analyze the collected data to develop detailed models that guide every construction phase. These models are instrumental in enhancing accuracy and efficiency, significantly reducing the risk of project delays.",
      img: "/3D Modeling Examples/Expert Analysis Photo.png",
    },
  ],
};

export const HomeSiteSimulations = {
  title: "Custom Site Simulations",
  description:
    "The future is the digital universe. Being able to create high fidelity digital twins of construction projects is going to be critical in developing our built environments. Custom site simulations provide a vibrant, interactive blueprint where the project really comes to life. This allows you to walk your workers through site specific safety inductions, create emergency plans and analyse specific procedures.",
  img: "/Construction Simulation Examples/Header PNG.png",
  content: [
    {
      title: "Real time problem solving ",
      content:
        "Being able to simulate procedures, lift plans and other construction projects in a virtual environment will allow you to immediately discover any risks or identify more efficient methods of construction.",
      img: "/Construction Simulation Examples/Realtime Problem Solving.png",
    },
    {
      title: "Enhanced Stakeholder Engagement",
      content:
        "Bring your project stakeholders into a dynamic and immersive environment where they can see, interact with, and provide feedback on the proposed plans. This engagement helps to ensure that the final construction aligns perfectly with the expectations of all involved parties.",
      img: "/Construction Simulation Examples/Enhanced Stakeholder Engagement Photo.png",
    },
    {
      title: "Site Specific Safety Inductions",
      content:
        "Some sites are quite large and have multiple parties collaborating on them. Having to constantly do site inductions can prove to be disruptive and could cause delays. But with a high fidelity digital twin of the site and its specific risks you can easily convey to a large group of people the risks on a site.",
      img: "/Construction Simulation Examples/RF Sons Safety Simulator .mp4",
    },
    {
      title: "Dynamic Modeling",
      content:
        " Leveraging advanced 3D modeling tools, we create dynamic simulations that mirror every aspect of the proposed site with incredible accuracy and detail. These models serve as the foundation for all subsequent planning and analysis.",
      img: "/Construction Simulation Examples/Dynamic Modelling Photo.png",
    },
    {
      title: "Interactive Scenarios",
      content:
        "We build various scenarios within the simulation to test different construction approaches, schedules, and responses to potential setbacks. This interactive testing ensures that the final plan is robust, flexible, and ready for any challenge.",
      img: "/Construction Simulation Examples/Interactive Scenarios Photo.png",
    },
    {
      title: "Continuous Feedback Loop",
      content:
        "As the project evolves, so does our simulation. We maintain a continuous feedback loop with clients, incorporating their insights and updates into the simulation. This iterative process guarantees that the simulation always represents the most current and effective project strategy.",
      img: "/Construction Simulation Examples/Continuous Feedback Loop Photo.png",
    },
  ],
};
