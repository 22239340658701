export const JubileeBridge = {
  title: "Jubilee Bridge",
  solution:
    "Working closely with all stakeholders to develop a robust construction methodology plan by deploying the use of drone scans, 3D modelling and senior expertise. Our approach focused on striking a balance between affordability and constructability.",
  image: "/Jubilee Bridge/HomePageBackgroundPic.png",
  link: "/home_portfolio/jubilee_bridge",
  client: "Auckland Council",
  location: "New Zealand",
  services: "Custom 3D Modeling & Construction Methodology",
  problem: {
    content:
      "Crafting a tender submission for the replacement of Jubilee Bridge, with a budget of $7.5 million, presented significant challenges. Marine bridge construction complexities and stringent constructability constraints posed hurdles that needed innovative solutions to overcome.",
    image: "/Jubilee Bridge/Problem Video.mp4",
  },
  outcome: {
    content:
      "The culmination of our efforts is evident in the winning tender submission that included 10 of our detailed construction methodology drawings. The drawings demonstrated the entire plan from demolition of the existing bridge all the way to offsite construction and installation of the new bridge.",
    image: [
      "/Jubilee Bridge/WorkExample1.png",
      "/Jubilee Bridge/WorkExample2.png",
      "/Jubilee Bridge/WorkExample3.png",
    ],
  },
};

export const P2PElectrification = {
  title: "P2P Electrification",
  solution:
    "CLL and other key stakeholders proposed a new design, which was used for the first time on the Auckland network. this new design necessitated a novel construction methodology to address the unique challenges of the project.",
  image: "/P2P Electrification/Header PNG.png",
  link: "/home_portfolio/p2p_electrification",
  client: "CLL",
  location: "Auckland, New Zealand",
  services:
    "Facilitate methodology workshops&3D models of key equipment&Game rigging of equipment models&Animation of the proposed methodology&Corridor model of work area",
  problem: {
    content:
      "The p2p project involved electrifying several kilometers of rail between Papakura and Pukekohe, which posed significant program constraints. the foundation works needed to be completed under single-line-running conditions, complicating the construction process.",
    image: "/P2P Electrification/Problem Photo.png",
  },
  outcome: {
    content:
      "The innovative construction methodology was turned into an animated sequence. this animation clearly outlined CLL's capabilities and demonstrated how the works could be completed effectively under the project's constraints. this tool likely served to communicate the process clearly to all stakeholders involved, ensuring alignment and understanding.",
    image: [
      "/P2P Electrification/Outcome 1.png",
      "/P2P Electrification/Outcome 2.png",
      "/P2P Electrification/Outcome 3.png",
    ],
  },
};

export const SHLoopRoadTender = {
  title: "SH Loop Road Tender",
  solution:
    "To address these challenges, several services were provided. a drone flyover of the site route was conducted to assess the area and gather detailed visual data. a corridor model for the entire project was created to visualize and plan the construction process. schedule quantities were generated to accurately estimate materials and resources needed. methodology workshops were facilitated to align project stakeholders and refine construction strategies. a geotechnical subsurface model was built to understand and plan for the ground conditions. additionally, 3d modeling of the bridge's temporary works was performed to ensure structural integrity and feasibility. finally, the methodology was published to document and share the planned approach with relevant parties.",
  image: "/Sh Loop Road Tender/Header PNG.png",
  link: "/home_portfolio/sh_loop_road_tender",
  client: "Oxcon CLL",
  location: "Whangarei New Zealand",
  services:
    "Develop draft construction methodologies&Facilitate workshops with designers and constructors&Develop construction programme&Develop construction budget.",
  problem: {
    content:
      "The contractor was tasked with delivering the methodology submission for their largest tender to date, which involved complex challenges. the project required maintaining two-way traffic on a state highway during construction, estimating substantial earthworks for an embankment, and implementing significant ground improvements before bridge.",
    image: "/Sh Loop Road Tender/Problem Photo.png",
  },
  outcome: {
    content:
      "The key outputs of these services included a comprehensive schedule of quantities, detailing all materials and resources required for the project, and detailed tender submission drawings, which are used to present the construction plan and methodologies to stakeholders for approval and funding.",
    image: [
      "/Sh Loop Road Tender/Outcome 1.png",
      "/Sh Loop Road Tender/Outcome 2.png",
      "/Sh Loop Road Tender/Outcome 3.png",
    ],
  },
};

export const StAlbertStreet = {
  title: "50 Albert Street",
  solution:
    "To address these challenges, collaborative efforts were made with all key subcontractors, designers, and the principal. simple 3d models of the construction methodology were used to facilitate workshops with designers and constructors, where issues were identified and solutions developed. these workshops led to the development of draft construction methodologies, a detailed construction programme, and a construction budget. the agreed methodologies were then published to guide site teams in their implementation.",
  image: "/st.albert/Header PNG.png",
  link: "/home_portfolio/st_albert_street",
  client: "CLL",
  location: "Auckland New Zealand",
  services:
    "Drone flyover of site route&Corridor modelling of entire project&Generate schedule quantities&Facilitate methodology workshops&Build geotechnical subsurface model&3D modelling of bridge temporary works&Publish methodology",
  problem: {
    content:
      "In this engagement, the project involved constructing the foundations for a multi-storey office building in the busy auckland cbd. the main challenges were the demolition of the existing building and retaining walls, which supported the newly constructed city rail link (crl) tunnel and busy albert street. this task was complicated further by the need to maintain tight tolerances adjacent to existing buildings and the construction of complex temporary retaining walls to support the excavation.",
    image: "/st.albert/Problem Photo.png",
  },
  outcome: {
    content:
      "The engagement produced several key outputs, including detailed construction methodology drawings that provided a visual and technical guide for the project. additionally, a resourced construction programme was developed, which outlined the timeline, resources, and processes needed to complete the project efficiently and effectively.",
    image: [
      "/st.albert/Outcome 1.png",
      "/st.albert/Outcome 2.png",
      "/st.albert/Outcome 3.png",
    ],
  },
};
