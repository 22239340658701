import Router from "./Router";
import { createContext, React, useContext, useReducer } from "react";
import { theme, App } from "antd";

const ThemeContext = createContext(null);

const initialTheme = {
  theme: theme.defaultAlgorithm,
  name: "light",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "lightMode":
      return { theme: theme.defaultAlgorithm, name: "light" };
    case "darkMode":
      return { theme: theme.darkAlgorithm, name: "dark" };
    default:
      throw new Error();
  }
};

const ThemeSwitchProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialTheme);
  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

export default function myApp() {
  return (
    <App>
      <ThemeSwitchProvider>
        <Router />
      </ThemeSwitchProvider>
    </App>
  );
}
